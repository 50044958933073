import React, {useState} from "react";
import { getSessionItem } from '../../utils/utils';
import { Button } from "reactstrap";
import { Dialog } from "primereact/dialog"
import EnterTicket from "views/IT/Tickets/EnterTicket";
import { useTranslation } from "react-i18next";

const EnterTicketButton = (props) => {
  const {t, i18n} = useTranslation(['it', 'common']);

  const [showEnterTicket, setShowEnterTicket] = useState(false);

  return (
    <>
      <Button
        className="layout-topbar-action"
        type="button"
        color=" "
        onClick={() => setShowEnterTicket(true)}
      >
        <span className="btn-inner--icon">
          <i className="fa fa-lg fa-bug" />
        </span>
      </Button>
      <Dialog
        visible={showEnterTicket}
        onHide={() => setShowEnterTicket(false)}
        header={t('it:EnterTicket')}
        style={{width: '50vw'}}
        breakpoints={{'1700px': '75vw', '1000px': '100vw'}}
      >
        <EnterTicket pageUrl={getSessionItem("url", "")} />
      </Dialog>
    </>
  )

}

export default EnterTicketButton;